import { formatDate } from "./FormatPrice";

const Comment = ({ name, date, content }) => (
  <div className="p-4 border-b border-gray-200">
    <h4 className="font-semibold">{name}</h4>
    <p className="text-sm text-gray-500">{formatDate(date)}</p>
    <p className="mt-2">{content}</p>
  </div>
);

export default Comment
