import React, { useEffect, useState, useRef } from "react";
import { colors } from "../../utils/colors";
import CustomTextInput from "../../components/CustomTextInput";
import { Checkbox, Col, Row } from "antd";
import CustomButton from "../../components/CustomButton";
import { auth } from "../../services/api/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Loading";
import dummy from "../../assets/dummy.jpg";
import States from "../../states";

const VendorDetails = () => {
  const [shortInfo, setShortInfo] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [storeName, setStoreName] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState("");

  const [canSubmit, setCanSubmit] = useState(false);
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);

  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      phoneNumber !== "" &&
      // city !== "" &&
      storeName !== "" &&
      address !== "" &&
      state !== "" &&
      shortInfo !== "" &&
      photo !== ""
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [
    email,
    password,
    photo,
    address,
    storeName,
    state,
    phoneNumber,
    confirmPassword,
    shortInfo,
  ]);

  useEffect(() => {
    checkPasswordLength && setTimeout(() => setCheckPasswordLength(false), 2000);
  }, [checkPasswordLength]);

  function onSubmit() {
    if (password !== confirmPassword) {
      setCanSubmit(false);
    } else if (password.length < 6) {
      setCheckPasswordLength(true);
    } else {
      // setCanSubmit(false);
      setIsLoading(!loading);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("name", storeName);
      formData.append("phone", phoneNumber);
      formData.append("address", address);
      formData.append("password", password);
      formData.append("password_confirmation", confirmPassword);
      formData.append("vendor_short_info", shortInfo);
      formData.append("photo", photo);

      // for (var pair of formData.entries()) {
      //     console.log(pair);
      //   }

      auth
        .registerVendor(formData)
        .then((response) => {
          if (response.status === 200) {
            toast.success(response.data?.message, { autoClose: 1000 });
            navigate("https://dashboard-ebn.netlify.app/");
          }
        })
        .catch((error) => {
          console.log("register",error)
          toast.error(error.data)
        }).finally(()=>setIsLoading(false))
    }
  }

  const handlePhoto = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleState = (e) => {
    setState(e.target.value);
  };
  return (
    <div>
      <main className="flex flex-row">
        <Row gutter={[30, 20]}>
          <Col>
            <CustomTextInput
              fieldName={""}
              placeholder={"Store Name"}
              value={storeName}
              setValue={setStoreName}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <CustomTextInput
              fieldName={""}
              placeholder={"Short Info"}
              value={shortInfo}
              setValue={setShortInfo}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <div className="my-2">
              <select
                name="state"
                id="state"
                onChange={handleState}
                value={state}
                className="h-[53px] border-[#24BA21] border-[1px]"
              >
                <option value="">select state</option>
                {States.map((state) => {
                  return <option key={state.capital}>{state.name}</option>;
                })}
              </select>
            </div>

            <div className="my-2">
              <div
                className="flex flex-col gap-4 cursor-pointer"
                onClick={() => ref.current.click()}
              >
                <label htmlFor="photo">Choose Picture</label>
                <img
                  src={photo ? photo : dummy}
                  alt="dummy"
                  className={photo ? "hidden" : "w-[150px] h-[150px]"}
                />
                <input
                  type="file"
                  name="photo"
                  id="photo"
                  onChange={handlePhoto}
                  ref={ref}
                  className="hidden"
                />
              </div>
              {photo && (
                <img
                  src={URL.createObjectURL(photo)}
                  alt="profile"
                  className="w-[150px] h-[150px] mt-4"
                />
              )}
            </div>
            {/* <CustomTextInput
              fieldName={""}
              placeholder={"City"}
              value={city}
              setValue={setCity}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <CustomTextInput
              fieldName={""}
              placeholder={"State"}
              value={state}
              setValue={setState}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            /> */}
          </Col>
          <Col>
            <CustomTextInput
              fieldName={""}
              placeholder={"Full Address"}
              value={address}
              setValue={setAddress}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <CustomTextInput
              fieldName={""}
              placeholder={"Phone Number"}
              value={phoneNumber}
              setValue={setPhoneNumber}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <CustomTextInput
              fieldName={""}
              placeholder={"Email"}
              value={email}
              setValue={setEmail}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />

            <CustomTextInput
              fieldName={""}
              placeholder={"Password"}
              type={"password"}
              value={password}
              setValue={setPassword}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
            <CustomTextInput
              fieldName={""}
              placeholder={" Confirm Password"}
              type={"password"}
              value={confirmPassword}
              setValue={setConfirmPassword}
              width={322}
              height={53}
              borderRadius={7}
              className={"w-full"}
              borderColor={colors.green}
            />
          </Col>
        </Row>
      </main>
      {/* <div className="flex items-center gap-8 mb-4 mt-5">
        <CustomTextInput
          fieldName={""}
          placeholder={"Security code"}
          type={"text"}
          value={number}
          setValue={setNumber}
          height={53}
          borderRadius={7}
          className={"text-xs"}
          borderColor={colors.green}
        />
        <div
          className="w-[120px] h-[53px] flex flex-row justify-center items-center text-xs rounded-md"
          style={{ backgroundColor: colors.green, opacity: 0.5 }}
        >
          1293845
        </div>
      </div> */}
      <Checkbox className="my-5">I agree to terms & policy</Checkbox>

      <div className="">
        <CustomButton
          text={loading ? <Loading /> : "Submit"}
          disabled={!canSubmit ? true : false}
          paddingTop={16}
          paddingBottom={16}
          paddingLeft={36}
          paddingRight={36}
          textWeight={400}
          background={colors.green}
          color={colors.white}
          borderRadius={4}
          onClick={onSubmit}
        />
      </div>
      <div
        className="text-xs font-semibold w-2/4 mt-5"
        style={{ color: colors.gray4 }}
      >
        <span style={{ color: colors.gray3 }} className="text-sm pr-1 font-bold">
          Note:
        </span>
        <p>
          Your personal data will be used to support your experience throughout this
          website to manage acess to your account, and for other purposes described
          in our privacy policy
        </p>
      </div>
    </div>
  );
};

export default VendorDetails;
