import React from "react";
import { useEffect, useState } from "react";
import api from "../services/api";
import { formatPrice } from "../components/FormatPrice";

const BiddingHistory = () => {
  const [bidsHistory, setBidHistory] = useState([]);
  useEffect(() => {
    api.customer.bidding
      .getAllBiddings()
      .then((res) => {
        setBidHistory(res.data?.bidding);
      })
      .catch((res) => console.log(res));
  }, []);
  return (
    <section>
      <div>
        <h1 className="text-2xl">Bidding History</h1>
      </div>
      <div className="px-3 overflow-x-auto my-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="tableHeadRow">
              <th className="tableHead">Auction id</th>
              {/* <th className="tableHead">Vendor Details</th> */}
              <th className="tableHead">Bid Price</th>
              <th className="tableHead">Date</th>
              <th className="tableHead">Time</th>
              {/* <th className="tableHead">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {bidsHistory.map((bids) => {
              const dateTime = new Date(bids.created_at);
              const date = dateTime.toISOString().split("T")[0];
              const time = dateTime.toISOString().split("T")[1].split(".")[0];
              return (
                <tr key={bids.id} className="tableRow">
                  <td>{bids.auction_id}</td>
                  {/* <td>nIYISHOP</td> */}
                  <td>₦{formatPrice(bids.bidding_price)}</td>
                  <td>{date}</td>
                  <td>{time}</td>
                  {/* <td>Ongoing</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default BiddingHistory;
