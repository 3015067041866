import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {formatPrice} from '../FormatPrice'
import api from "../../services/api";

const AuctionCard = ({
 thumbnail,
 productName,
 productDetail,
 productStatus,
 productCurrentBid,
 id,
}) => {
 const navigate = useNavigate();
 const { user } = useSelector((store) => store.auth);
 const [inWatchList, setInWatchlist] = useState(false);


 useEffect(()=>{
  api.customer.watchlist.getWatchlist().then(
    res=>{
      const isItemInCart = res.data?.watchlist.find((item) => item.auction_id === id);
      setInWatchlist(isItemInCart ? true : false);
    }).catch(err=>console.log(err))
 },[id])

 const addToWatchlist = () => {
  api.customer.watchlist.addToWatchlist(id).then(res=>console.log(res)).catch(console.error)
 }
 const deleteFromWatchlist = () => {
  api.customer.watchlist.deleteWatchlistItem(id).then(res=>console.log(res)).catch(console.error)
 }

 const addToWish = (e) => {
  e.stopPropagation();
  console.log("ID in addToWish:", productStatus);
  if (user) {
    if(!inWatchList && productStatus !== 'close'){
      addToWatchlist()
    }else{
      deleteFromWatchlist()
    }
  } else {
   navigate("/register");
  }
 };
 const viewAuction = (e) => {
  e.stopPropagation();
  if (user) {
   navigate(`/auctions/auction/${id}`);
  } else {
   navigate("/login");
  }
 };

 return (
  <article className="auctionCard" id={id} onClick={viewAuction}>
   <figure>
    <img src={thumbnail} alt="thumbnail" />
    <figcaption
     className={
      productStatus === "open" ? "auctionOn py-1 px-2" : "noAuction py-1 px-2"
     }
    >
     {productStatus === "open" ? "Live Auction" : "Auction Ended"}
    </figcaption>
   </figure>
   <div id="auctionCard_details">
    <article>
     <div>
      <h2>{productName}</h2>
      <p>{productDetail}</p>
     </div>
     {/* <h3>CHARLOTTE, NC</h3> */}
     <h3
      className={productStatus === "open" ? "text-green-700" : "text-red-800"}
     >
      {productStatus === "open" ? "Live NOW" : "Ended"}
     </h3>
     <h1 className="text-lg">₦{formatPrice(productCurrentBid)}</h1>
    </article>
    <div className="flex justify-between">
     <button
      type="button"
      className={user ? "add" : "noUser"}
      onClick={addToWish}
      disabled={productStatus === 'close'}
     >
      {user 
    ? (productStatus !== 'close' 
        ? (inWatchList ? "Remove from Wishlist" : "Add to Wishlist") 
        : "Can't Add") 
    : "Register"}
     </button>
     <button
      type="button"
      className={
       productStatus === "open" ? "auctionOn py-2 px-4" : "noAuction py-2 px-4"
      }
     >
      {productStatus === "open" ? "Bid" : "Bid Ended"}
     </button>
    </div>
   </div>
  </article>
 );
};

export default AuctionCard;
