import { Link, NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { IoMdLogIn } from "react-icons/io";
import { MdCancel, MdManageAccounts } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { BsCart } from "react-icons/bs";
import { CiStopwatch } from "react-icons/ci";
import ebn from "../assets/ebn.svg";
import search from "../assets/searchicon.svg";
import person from "../assets/person.svg";
import call from "../assets/call.JPG";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineShoppingCart, MdPersonOutline } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { logout } from "../redux/Features/authSlice";
import service from "../services";
import { LuLogIn } from "react-icons/lu";
import { RiAuctionFill } from "react-icons/ri";

export const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [query, setQuery] = useState("");
  const [isAccount, setIsAccount] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cartQty } = useSelector((store) => store.cart);
  const { wishes } = useSelector((store) => store.wishlist);
  const { user } = useSelector((store) => store.auth);

  const handleLogout = () => {
    dispatch(logout());
    service.reloadPage();
    window.location.replace("/");
  };

  return (
    <Container>
      <TopNav>
        <div className="desktop">
          <div className="left">
            <Link className="top-link" to="/cart">
              My Cart
            </Link>
          </div>

          <div className="right">
            <p className="top-link">
              Need Help? Call Us:{" "}
              <span>
                <a href="tel:+2349130003337">+2349130003337</a>
              </span>
            </p>
          </div>
        </div>
        <div className="menu">
          <button type="button" onClick={() => setIsMobile(!isMobile)}>
            {isMobile ? <RxCross2 /> : <GiHamburgerMenu />}
          </button>
        </div>
        <div className="icons flex sm:hidden gap-2 items-center">
          <div className="icon-box" onClick={() => setIsAccount(!isAccount)}>
            {user ? (
              <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                <img
                  src={
                    "https://ebn.itl.ng/public/upload/user_images/" +
                    user?.photo
                  }
                  alt="profile"
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <img src={person} alt="icon" className="w-[25px]" />
            )}
          </div>
        </div>
        {isAccount && (
          <div className="md:hidden w-auto shadow-xl z-50 absolute top-12 right-4 bg-slate-50 px-4 py-4 ">
            {user && (
              <>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <MdManageAccounts />
                  <Link to="/myaccount/profile">My Account</Link>
                </li>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <CiStopwatch />
                  <Link to="/watchlist">Watchlist</Link>
                </li>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <RiAuctionFill />
                  <Link to="/bids-won">Bids won</Link>
                </li>
              </>
            )}
            <li
              className="flex items-center gap-2 list-none py-1"
              onClick={() => setIsAccount(!isAccount)}
            >
              <BsCart />
              <Link to="/cart">Cart</Link>
            </li>
            <li
              className="flex items-center gap-2 list-none py-1"
              onClick={() => setIsAccount(!isAccount)}
            >
              <FaRegHeart />
              <Link to="/wishlist">My Wishlist</Link>
            </li>
            <li
              className="flex items-center gap-2 list-none py-1"
              onClick={() => setIsAccount(!isAccount)}
            >
              <IoMdLogIn />
              {!user ? (
                <Link to="/register">Sign Up</Link>
              ) : (
                <button type="button" onClick={handleLogout}>
                  Logout
                </button>
              )}
            </li>
            {!user && (
              <li
                className="flex items-center gap-2 list-none py-1"
                onClick={() => setIsAccount(!isAccount)}
              >
                <LuLogIn />
                <Link to="/login">Login</Link>
              </li>
            )}
          </div>
        )}
      </TopNav>

      <div className="line"></div>

      <MiddleNav>
        <div className="logo">
          <img src={ebn} alt="ebn-logo" className="w-2/4" />
          <h3>
            <span className="elect">ELECTRONICS</span>
            <span className="bid"> Bidding</span>
            <span className="nig"> Nigeria</span>
          </h3>
        </div>

        <div className="search w-full md:w-[50%] flex gap-3 justify-center">
          {/* <select id="select" className="w-[40%]">
      <option value="1" key="one" selected>
       All Categories
      </option>
     </select> */}
          {/* <p className="divisor">|</p> */}
          <div className="search-box w-full relative">
            <input
              type="text"
              placeholder="Search for items..."
              className="border-2 w-full border-green-500 py-2 px-1 indent-2 outline-none"
              value={query}
              name="query"
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={() => {
                navigate(`/products/${query}`);
                setQuery("");
              }}
            />
            <img
              src={search}
              alt="icon"
              className="absolute right-1 w-[25px] top-[25%]"
              onClick={() => {
                navigate(`/products/${query}`);
                setQuery("");
              }}
            />
          </div>
        </div>

        <div className="icons">
          <div className="icon-box" onClick={() => navigate("/wishlist")}>
            <div className="relative">
              <AiOutlineHeart className="text-[25px]" />
              <p className="count">{wishes}</p>
            </div>
            <p className="text-[13px]">Wishlist</p>
          </div>

          <div className="icon-box" onClick={() => navigate("/cart")}>
            <div className="relative">
              <MdOutlineShoppingCart className="text-[25px]" />
              <p className="count">{cartQty}</p>
            </div>
            <p className="text-[13px]">Cart</p>
          </div>

          <div className="icon-box" onClick={() => setIsAccount(!isAccount)}>
            <div>
              {user ? (
                <div className="w-[40px] h-[40px] rounded-full overflow-hidden">
                  <img
                    src={
                      "https://ebn.itl.ng/public/upload/user_images/" +
                      user?.photo
                    }
                    alt="profile"
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <MdPersonOutline className="text-[25px]" />
              )}
            </div>
            <p>Account</p>
          </div>
        </div>
        {isAccount && (
          <div className="hidden md:block w-auto shadow-xl z-50 absolute top-[90px] right-4 bg-slate-50 px-4 py-4">
            {user && (
              <>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <MdManageAccounts />
                  <Link to="/myaccount/profile">My Account</Link>
                </li>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <CiStopwatch />
                  <Link to="/watchlist">Watchlist</Link>
                </li>
                <li
                  className="flex items-center gap-2 list-none py-1"
                  onClick={() => setIsAccount(!isAccount)}
                >
                  <RiAuctionFill />
                  <Link to="/bids-won">Bids won</Link>
                </li>
              </>
            )}
            <li
              className="flex items-center gap-2 list-none py-1"
              onClick={() => setIsAccount(!isAccount)}
            >
              <IoMdLogIn />
              {!user ? (
                <Link to="/register">Sign Up</Link>
              ) : (
                <button type="button" onClick={handleLogout}>
                  Logout
                </button>
              )}
            </li>
            {!user && (
              <li
                className="flex items-center gap-2 list-none py-1"
                onClick={() => setIsAccount(!isAccount)}
              >
                <LuLogIn />
                <Link to="/login">Login</Link>
              </li>
            )}
            <MdCancel
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setIsAccount(!isAccount)}
            />
          </div>
        )}
      </MiddleNav>

      <div className="line"></div>

      <DesktopNav>
        <nav>
          <StyledNavLink to="/">Home</StyledNavLink>
          <StyledNavLink to="/products">Products</StyledNavLink>
          <StyledNavLink to="/vendors">Vendors</StyledNavLink>
          <StyledNavLink to="/auctions">Bidding</StyledNavLink>
          <StyledNavLink to="/contact-us">Contact</StyledNavLink>
        </nav>

        <div className="flex gap-1 justify-center">
          <img src={call} alt="" />

          <div className="flex flex-col gap-1">
            <a
              href="tel:+09130003337"
              className="text-green-500 font-medium text-2xl"
            >
              09130003337
            </a>
            <small className="">24/7 Support Center</small>
          </div>
        </div>
      </DesktopNav>

      {isMobile && (
        <MobileNav>
          <nav>
            <StyledNavLink to="/" onClick={() => setIsMobile(!isMobile)}>
              Home
            </StyledNavLink>
            <StyledNavLink
              to="/products"
              onClick={() => setIsMobile(!isMobile)}
            >
              Product
            </StyledNavLink>
            <StyledNavLink to="/vendors" onClick={() => setIsMobile(!isMobile)}>
              Vendors
            </StyledNavLink>
            <StyledNavLink
              to="/auctions"
              onClick={() => setIsMobile(!isMobile)}
            >
              Auctions
            </StyledNavLink>
            <StyledNavLink
              to="/contact-us"
              onClick={() => setIsMobile(!isMobile)}
            >
              Contact
            </StyledNavLink>
          </nav>

          <div className="help">
            <img src={call} alt="" height={30} width={25} />

            <div className="num-info">
              <p className="num">1900-888</p>
              <p className="center">24/7 Support Center</p>
            </div>
          </div>
        </MobileNav>
      )}

      <div className="line"></div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .line {
    border-top: 1px solid #7b78785c;
  }
`;

const TopNav = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;

  .desktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      color: rgba(123, 120, 120, 0.36);

      .top-link {
        color: rgba(123, 120, 120, 0.36);
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .right {
      .top-link {
        color: rgba(123, 120, 120, 0.36);
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;

        span {
          color: #3398ff;
        }
      }
    }
  }

  .menu {
    display: none;
  }

  @media (max-width: 430px) {
    justify-content: space-between;
    padding: 0px 10px;

    .desktop {
      display: none;
      .right {
        .top-link {
          display: flex;
          gap: 20px;
          justify-content: space-between;
        }
      }
    }
    .menu {
      display: block;
      font-size: 30px;
    }
  }
`;
const MiddleNav = styled.div`
  padding: 10px;
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: flex;
    flex-direction: column;
    width: 25%;

    h3 {
      text-transform: uppercase;
      font-size: 1rem;
      /* width: 100px; */

      .elect {
        color: #e43137;
      }
      .bid {
        color: #0063d1;
      }
      .nig {
        color: #049b04;
      }
    }
  }

  /* .search {
  border: 1px solid #049b04;
  border-radius: 3px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-evenly;
  gap: 5px;

  select {
   border: none;
   outline: none;
   color: rgba(123, 120, 120, 0.36);
   font-weight: 500;
   cursor: pointer;

   option {
    background: #0063d1;
    color: #000;
    font-size: 16px;
    cursor: pointer;
   }
  }
  .divisor {
   font-size: 20px;
   color: rgba(123, 120, 120, 0.36);
  }

  .search-box {
   border: none;
   outline: none;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 0px 10px;

   input {
    border: none;
    outline: none;
   }
   input::placeholder {
    color: rgba(123, 120, 120, 0.36);
   }

   img {
    cursor: pointer;
   }
  }
 } */

  .icons {
    display: flex;
    align-items: end;
    gap: 15px;

    .icon-box {
      display: flex;
      align-items: baseline;
      gap: 5px;
      cursor: pointer;
      font-size: 13px;
    }
  }
  @media (max-width: 430px) {
    flex-direction: column;
    .logo {
      width: 100%;
      align-items: center;
    }
    .icons {
      display: none;
    }
  }
`;

const DesktopNav = styled.div`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  nav {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .help {
    display: flex;
    align-items: center;
    font-size: 1rem;
    gap: 7px;

    .num-info {
      display: flex;
      flex-direction: column;

      .num {
        color: #3398ff;
        cursor: pointer;
      }

      .center {
        color: rgba(123, 120, 120, 0.36);
        font-weight: 600;
        margin-top: -15px;
      }
    }
  }
  @media (max-width: 430px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: none;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;

  nav {
    display: "flex";
    align-items: center;
    gap: 40px;
  }

  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 45px;
    width: 100%;
    background: rgba(29, 124, 30, 0.44);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.6px);
    -webkit-backdrop-filter: blur(3.6px);
    height: 100vh;
    transition: all 3s ease-in-out;
    nav {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 20px;
    }
    .help {
      display: none;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  transition: all 0.7s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: bold;
    color: #049b04;
    border-bottom: 2px solid #049b04;
  }
  @media (max-width: 430px) {
    font-weight: bold;
    &.active {
      color: white;
    }
  }
`;
