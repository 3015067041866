import React, { useState } from "react";
import api from "../services/api";
import { useEffect } from "react";
import { formatDate, formatPrice } from "../components/FormatPrice";

const TransactionHistory = () => {
 const [transactions, setTransactions] = useState('');
 useEffect(() => {
  api.customer.profile
   .getTransactions()
   .then((res) => {
    console.log(res)
    setTransactions(res.data?.Transactions);
   })
   .catch((res) => console.log(res));
 }, []);
 return (
  <section>
   <div>
    <h1 className="text-2xl">Transaction History</h1>
   </div>
   <div className="px-3 overflow-auto my-4 w-full">
    <table className="divide-y divide-gray-200 min-w-full">
     <thead>
      <tr className="tableHeadRow">
       <th className="tableHead">Order number</th>
       <th className="tableHead">Paystack ref</th>
       <th className="tableHead">Date </th>
       <th className="tableHead">Total price</th>
       <th className="tableHead">Status</th>
      </tr>
     </thead>
     <tbody>
      {transactions && (
       transactions?.map((x, y) => {
        return (
         <tr className="tableRow py-2" key={x.id + y}>
          <td>{x.order_number}</td> 
          <td>{x.paystack_ref}</td>
          <td>{formatDate(x.created_at)}</td>
          <td>{formatPrice(x.total_price / 100)}</td>
          <td>delivered</td>
         </tr>
        );
       })
      ) }
     </tbody>
    </table>
    
       {!transactions && 
       <div>
        <h1>No transactions yet</h1>
       </div>
       }
      
   </div>
  </section>
 );
};

export default TransactionHistory;
