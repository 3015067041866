import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { GrAttachment } from "react-icons/gr"
import { toast } from 'react-toastify'
import api from '../services/api'


const RaiseClaim = () => {
  const { user } = useSelector((store) => store.auth);

  const [complaints, setComplaints] = useState({
    name: user?.name,
    email: user?.email,
    phone: user?.phone,
    message: '',
    img1: null,
    img2: null,
    // orderNumber: ''
  })
  const [success, setSuccess] = useState(false);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleImg1Upload = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };
  const handleImg2Upload = () => {
    if (fileInputRef2.current) {
      fileInputRef2.current.click();
    }
  };

  const handleFileChange = (e, fieldName) => {
    const selectedFile = e.target.files[0];

    setComplaints((prevComplaints) => ({
      ...prevComplaints,
      [fieldName]: selectedFile,
    }));

    // if (fileInputRef.current) {
    //   fileInputRef.current.value = null;
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setComplaints({
      ...complaints,
      [name]: value
    })
  }

  const isAnyFieldEmpty = () => {
    for (const key in complaints) {
      if (complaints[key] === '') {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setSuccess(!success);
    if (isAnyFieldEmpty()) {
      toast.info('Please fill in all fields before submitting.');
      return;
    }
    const formData = new FormData()

    formData.append('name', complaints.name)
    formData.append('email', complaints.email)
    formData.append('description', complaints.message)
    formData.append('img1', complaints.img1)
    formData.append('img2', complaints.img2)


    api.customer.raiseClaim(formData).then((res) => {
      console.log(res); toast.success(res.data?.message); setComplaints({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        message: '',
        img1: null,
        img2: null,
        // orderNumber: ''
      })
    }).catch((res) => console.log(res))
  }
  return (
    <div>
      <div className='flex flex-col gap-1 justify-center items-center mb-[20px]'>
        <h2 className='text-[35px] text-center font-bold'>Have any issue with a product ??</h2>
        <p className='text-[16px] font-medium'>Fill the form and we would get back to you.</p>
      </div>

      <form onSubmit={handleSubmit}>
        <section className='grid grid-cols-1 md:grid-cols-2 gap-3'>
          <div className="form-input">
            <label htmlFor="name" className="font-medium text-base">
              Name
            </label>
            <input
              type="text"
              name="name"
              id=""
              value={complaints.name}
              disabled
              className="border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
            />
          </div>
          <div className="form-input">
            <label htmlFor="email" className="font-medium text-base">
              EMAIL ADDRESS
            </label>
            <input
              type="text"
              name="email"
              id=""
              value={complaints.email}
              disabled
              className=" border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
            />
          </div>
          <div className="form-input">
            <label htmlFor="orderNumber" className="font-medium text-base">
              Order Number
            </label>
            <input
              type="text"
              name="orderNumber"
              id="" value={complaints.orderNumber}
              onChange={handleChange}
              className=" border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
            />
          </div>
          <div className="form-input">
            <label htmlFor="message" className="font-medium text-base">
              MESSAGE
            </label>
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              value={complaints.message}
              onChange={handleChange}
              className="border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
            />
          </div>
        </section>

        <div className='my-3 flex flex-wrap gap-4'>

          <section>
            <div onClick={handleImg1Upload} className='my-4 flex flex-col gap-4 items-center border-dotted border-[4px] border-green-400 py-2 px-6'>
              <GrAttachment className='text-[40px]' />
              <p>Attach 1 Image  here</p>
            </div>
            <div className='flex gap-3'>
              {complaints.img1 &&
                <img
                  src={URL.createObjectURL(complaints.img1)}
                  alt="Brand Logo"
                  width="100"
                  height="100"

                />
              }
            </div>
          </section>
          <section>
            <div onClick={handleImg2Upload} className='my-4 flex flex-col gap-4 items-center border-dotted border-[4px] border-green-400 py-2 px-6'>
              <GrAttachment className='text-[40px]' />
              <p>Attach File here</p>
            </div>
            <div className='flex gap-3'>
              {complaints.img2 &&
                <p>{complaints.img2.name}</p>
              }
            </div>
          </section>
        </div>
        <input
          type="file"
          name="img1"
          accept=".jpg,.png,.JPG"
          id="img1"
          multiple
          ref={fileInputRef1}
          style={{ display: 'none' }}
          onChange={(e) => handleFileChange(e, 'img1')}
        />
        <input
          type="file"
          name="img2"
          accept="*/"
          id="img2"
          multiple
          ref={fileInputRef2}
          style={{ display: 'none' }}
          onChange={(e) => handleFileChange(e, 'img2')}
        />

        <button
          disabled={success}
          type="button"
          className={success ? "disabled" : "submitBtn"}
          onClick={handleSubmit}
        >
          Send
        </button>
      </form>
    </div>
  )
}

export default RaiseClaim