import React, { useState } from "react";
import Logo from "./../assets/ebn.svg";
import ReactStars from "react-stars";
import Twitter from "./../assets/social-tw.svg";
import Facebook from "./../assets/social-fb.svg";
import Instagram from "./../assets/social-insta.svg";
import Pinterest from "./../assets/social-pin.svg";
import { MdOutlineLocationOn } from "react-icons/md";
import { AiOutlineCustomerService } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import api from "../services/api";
import NewProductCard from "../components/Cards/NewProductCard";
import Modal from "../components/Cards/Modal";
import { formatPrice } from "../components/FormatPrice";

const SingleVendor = () => {
  const [vendor, setVendor] = useState({});
  const [vendorProducts, setVendorProducts] = useState([]);
  const { vendorId } = useParams();

  const dateString = vendor?.created_at;
  const date = new Date(dateString);
  const year = date.getFullYear();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const finalPrice = selectedProduct?.discount_price
    ? selectedProduct?.selling_price -
      selectedProduct?.selling_price * (selectedProduct?.discount_price / 100)
    : selectedProduct?.selling_price;

  let sizes = [];
  if (selectedProduct && selectedProduct.product_size) {
    try {
      sizes = JSON.parse(selectedProduct.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (selectedProduct && selectedProduct?.product_color) {
    try {
      colors = JSON.parse(selectedProduct?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  const handleView = (productId) => {
    const product = vendorProducts.find((p) => p.id === productId);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    api.customer.vendor
      .getVendor(vendorId)
      .then((res) => {
        setVendor(res.data?.Vendor[0]);
      })
      .catch((res) => console.log(res));

    api.customer.vendor
      .getVendorProducts(vendorId)
      .then((res) => {
        setVendorProducts(res.data?.Products);
      })
      .catch((res) => console.log(res));
  }, [vendorId]);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(vendorProducts?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = vendorProducts?.slice(startIndex, endIndex);

  return (
    <main className="singleVendor">
      <div className="topSection">
        <h1 className="title">{vendor?.username}</h1>
      </div>

      <section className="bottomSection">
        <aside className="detailsArea">
          {/* details */}
          <article className="aboutDetails">
            {/* LOGO */}
            <figure>
              <img src={Logo} alt="logo" />
            </figure>
            {/* OTHER DETAILS */}
            <div className="vendorInfo">
              <small className="vendordate">Since {year}</small>
              <h1 className="vendorName">{vendor?.username}</h1>
              <ReactStars
                count={5}
                value={4.5}
                size={25}
                color2="#ff8800"
                edit={false}
              />
              <p className="vendorInfoText">{vendor?.vendor_short_info}</p>
            </div>
            <div className="socials">
              <h3>Follow Us </h3>
              <article className="social-icons">
                <Link to="">
                  <img src={Twitter} alt="socials-1" />
                </Link>
                <Link to="">
                  <img src={Facebook} alt="socials-2" />
                </Link>
                <Link to="">
                  <img src={Instagram} alt="socials-3" />
                </Link>
                <Link to="">
                  <img src={Pinterest} alt="socials-4" />
                </Link>
              </article>
              <article className="location">
                <div>
                  <MdOutlineLocationOn />
                  <p>
                    Address: <span>{vendor?.address}</span>
                  </p>
                </div>
                <div>
                  <AiOutlineCustomerService />
                  <p>
                    Call us: <span>{vendor?.phone}</span>
                  </p>
                </div>
              </article>
            </div>
            {/* <button type="button" className='submitBtn'>Contact Seller</button> */}
          </article>
        </aside>

        <section className="productsArea">
          <div>
            <p className="text-xl font-semibold pl-[15px]">
              we have{" "}
              <span className="text-green-400 font-medium italic">
                {paginatedData?.length > 0 ? paginatedData.length : 0}
              </span>{" "}
              products
            </p>
          </div>
          <section className="my-8 vendorsPageBottomSection">
            <div className="flex flex-wrap gap-4 justify-center">
              {paginatedData?.length > 0 &&
                paginatedData?.map((product) => (
                  <NewProductCard
                    onView={handleView}
                    key={product.id}
                    price={product.selling_price}
                    product_name={product.product_name}
                    rating={product.productRating}
                    image={
                      "https://api.ebn.ng/public" + product.product_thumbnail
                    }
                    id={product.id}
                    tag={product.productTag}
                    discount={product.discount_price}
                    createdBy={product.created_by}
                    slug={product.product_slug}
                  />
                ))}
            </div>
            {paginatedData?.length < 1 && (
              <div className="flex justify-center w-full">
                <h1 className="text-2xl">
                  I have no products for you yet!, check back soon
                </h1>
              </div>
            )}
            {/* <div className='allVendorProducts'>
              {paginatedData.map((product) => (
                <ProductCard
                  key={product.productId}
                  price={product.productPrice}
                  product_name={product.productName}
                  rating={product.productRating}
                  image={product.productImages[0]}
                  id={product.productId}
                  tag={product.productTag}
                />
              ))}console.log(res);
            </div> */}
            <div className="my-8">
              {paginatedData?.length > itemsPerPage && (
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              )}
            </div>
          </section>
        </section>
      </section>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && (
          <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
            {/* Product slider */}
            <div className="w-full lg:w-[40%]">
              <img
                src={"https://ebn.itl.ng/" + selectedProduct.product_thumbnail}
                alt={"just"}
                className="w-full  object-contain"
              />
            </div>
            {/* Product Details */}
            <aside className="w-full lg:w-[55%]">
              <h3 className="text-xl lg:text-2xl w-full">
                {selectedProduct?.product_name}
              </h3>

              <div className="flex flex-col gap-4 my-6">
                <article className="flex gap-3 items-center justify-between">
                  <p>Size :</p>
                  <select name="size" id="size" className="w-[80%]">
                    <option value="">Select a size</option>
                    {sizes.map((size) => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </article>
                <article className="flex gap-3  items-center justify-between">
                  <p>Color :</p>
                  <select name="color" id="color" className="w-[80%]">
                    <option value="">Select a color</option>
                    {colors.map((color) => {
                      return (
                        <option key={color} value={color}>
                          {color}
                        </option>
                      );
                    })}
                  </select>
                </article>
              </div>

              {/* the ratings and reviews with price section */}
              <div className="mt-[20px]">
                {/* details */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl lg:text-2xl my-2 font-bold">
                    ₦{formatPrice(finalPrice)}
                  </h3>
                  {selectedProduct.discount_price && (
                    <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                      ₦
                      <strike>
                        {formatPrice(selectedProduct.selling_price)}
                      </strike>
                    </h3>
                  )}
                </div>
                {/* extra details */}
                <div>
                  {/* buttons redux */}
                  <div className="mt-[20px]">
                    <div className="mt-4">
                      <button
                        type="button"
                        className="py-3 px-5 bg-green-700 text-white font-medium rounded-md"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Modal>
    </main>
  );
};

export default SingleVendor;
