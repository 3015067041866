import React from "react";
// import facebookWhite from "../assets/icons/facebookWhite.svg";
// import googleMultiColor from "../assets/icons/googleMultiColor.svg";
// import apple from "../assets/icons/apple.svg";
// import CustomButton from "../components/CustomButton";
// import { colors } from "../utils/colors";
import LoginDetails from "../components/SignUp/LoginDetails";

const Login = () => {
  return (
    <main className="py-12 flex flex-col lg:flex-row gap-4 justify-around items-center mx-auto">
      <section className="w-full md:w-[80%] lg:w-[50%]">
        <LoginDetails />
      </section>
      {/* <div
     className="px-6 pt-6 pb-16 w-fit rounded flex flex-col gap-9"
     style={{ border: `1px solid ${colors.lightGreen}` }}
    >
     <CustomButton
      leftIcon={facebookWhite}
      text={"Continue with facebook"}
      paddingTop={10}
      paddingBottom={10}
      paddingLeft={16}
      paddingRight={16}
      textWeight={400}
      background={colors.blue}
      color={colors.white}
      borderRadius={4}
      width={320}
      className={"text-white flex flex-row justify-start gap-2 items-center"}
      iconSize={32}
     />
     <CustomButton
      leftIcon={googleMultiColor}
      text={"Continue with google"}
      paddingTop={10}
      paddingBottom={10}
      paddingLeft={16}
      paddingRight={16}
      textWeight={400}
      background={colors.white}
      color={colors.gray3}
      borderRadius={4}
      width={320}
      border={`1px solid ${colors.green}`}
      className={"flex flex-row justify-start gap-2 items-center"}
      iconSize={32}
     />
     <CustomButton
      leftIcon={apple}
      text={"Continue with apple"}
      paddingTop={10}
      paddingBottom={10}
      paddingLeft={16}
      paddingRight={16}
      textWeight={400}
      background={colors.black}
      color={colors.white}
      borderRadius={4}
      width={320}
      className={"flex flex-row justify-start gap-2 items-center"}
      iconSize={32}
     />
    </div> */}

    </main>
  );
};

export default Login;
