import React, { useState } from "react";
import VendorDetails from "../components/SignUp/VendorDetails";
import CustomerDetails from "../components/SignUp/CustomerDetails";
import { Link } from "react-router-dom";

const SignUp = () => {
 const [userType, setUserType] = useState("vendor");

 const handleUserTypeChange = (e) => {
  setUserType(e.target.value);
 };

 return (
  <main className="w-full py-6 px-[30px]">
   <section>
    <div>
     <h3 className="text-4xl font-bold">Create an Account</h3>
     <p className="font-medium text-gray-500">
      Already have an account?{" "}
      <Link to={"/login"} className="text-green-600">
       Login
      </Link>
     </p>
    </div>
    <div className="flex flex-col items-center gap-4 my-3">
     <section className="userTypeContainer">
      <div className="userType">
       <input
        type="radio"
        name="userType"
        id="vendor"
        value="vendor"
        checked={userType === "vendor"}
        onChange={handleUserTypeChange}
        className="form-radio h-5 w-5"
       />
       <label htmlFor="vendor" className="font-medium">
        Vendor
       </label>
      </div>
      <div className="userType">
       <input
        type="radio"
        name="userType"
        id="customer"
        value="customer"
        checked={userType === "customer"}
        onChange={handleUserTypeChange}
        className="form-radio h-5 w-5"
       />
       <label htmlFor="customer" className="font-medium">
        Customer
       </label>
      </div>
     </section>
    </div>
   </section>
   {userType === "vendor" && <VendorDetails />}
   {userType === "customer" && <CustomerDetails />}
  </main>
 );
};

export default SignUp;
