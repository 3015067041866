import { Skeleton, Button } from "antd";
import CommentPicture from "../assets/comments.svg";
import Comment from "./Comment";

const CommentsSection = ({ comments, loading, onViewMore }) => {
  // Get the latest 5 comments
  const latestComments = comments.slice(0, 5);

  return (
    <div className="mt-8">
      {/* Button to view more comments */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Verified Customer Feedback</h2>
        {comments.length > 3 && (
          <Button type="primary" onClick={onViewMore}>
            View More Comments
          </Button>
        )}
      </div>

      {loading ? (
        <>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
        </>
      ) : (
        <>
          {/* Display Comments */}
          <div className="space-y-4">
            {latestComments.length > 0 ? (
              latestComments.map((comment, index) => (
                <Comment
                  key={index}
                  name={comment.username}
                  date={comment.timestamp}
                  content={comment.comment}
                />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center w-full mx-auto text-center mt-4">
                <img
                  src={CommentPicture}
                  alt="No comments"
                  className="w-24 h-24 mb-4"
                />
                <p className="text-gray-500 text-lg">
                  No comments yet. Be the first to comment!
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CommentsSection;
